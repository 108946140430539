<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/tamogiefectivo/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">PCA Información y Servicio INTE, S.A. de C.V.</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Página Delantera</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Sobre Nosotros</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/introduce' }"
          class="Li3"
        >
          <router-link to="/introduce" class="nav-link3">
            <span class="routeText3">Contáctenos</span>
          </router-link>
        </li>
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Política de Privacidad</span>
          </router-link>
          <a href="https://cre.pcacreditoapp.com/m7xD0C5a2/To5K9n7Pa.html" class="nav-link3" target="_blank">
            <span class="routeText4">Política de Privacidad</span>
          </a>
        </li> -->
        <li class="dropdown Li4">
          <!-- 添加下拉图标 -->
          <span class="dropdown-toggle routeText4">Política de Privacidad</span>
          <ul class="dropdown-menu">
            <li class="firstLi">
              <a
                href="https://cre.pcacreditoapp.com/m7xD0C5a2/To5K9n7Pa.html"
                target="_blank"
              >
                <img
                  src="@/page/pcainfoyserinte/components/img/app.png"
                  alt="App Store"
                  class="iconImg"
                />
                App Store
              </a>
            </li>
            <li>
              <a
                href="https://cre.pcacreditoapp.com/m7xD0C5a2/Tu1T8i49Paa.html"
                target="_blank"
              >
                <img
                  src="@/page/pcainfoyserinte/components/img/play.png"
                  alt="App Store"
                  class="iconImg"
                />
                Google Play</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div style="height: 110px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "../../src/page/jaidee/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  position: sticky; /* 将导航栏固定在页面上 */
  top: 0; /* 位于页面顶部 */
  z-index: 1000; /* 确保导航栏在其他元素上方 */
  height: 80px;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #ffffff;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 50px;
}

.title {
  width: 560px;
  height: 24px;
  font-size: 18px;
  font-family: Roboto Flex-SemiBold;
  font-weight: 500;
  color: #333333;
  line-height: 23px;
  /* padding-left: 18px; */
  padding-left: 80px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #333333;
  line-height: 29px;
  opacity: 0.7;
  /* text-transform: uppercase; */
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  /* padding: 8px; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 45px;
  /* padding-left: 4px;
  padding-right: 4px; */
  /* border: 1px solid red; */
  align-items: center;
  justify-content: center;
}
.Li1 {
  width: 180px;
}
.Li2 {
  width: 160px;
}
.Li3 {
  width: 140px;
}
.Li4 {
  width: 220px;
}
.lastLi {
  width: 190px;
  margin-right: 28px;
}
/* .active-link {
  background-size: 100% 100%;
} */
.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  /* font-size: 18px; */
  /* font-family: Inter-Extra Bold; */
  font-weight: 400;
  opacity: 1;
}
.active-link .routeText1::after,
.active-link .routeText2::after,
.active-link .routeText3::after,
.active-link .routeText4::after,
.active-link .routeText5::after {
  content: "";
  display: block;
  /* 初始时下划线可见 */
  border-bottom: 4px solid #333333;
  border-radius: 20px;
  /* 调整下划线的长度 */
  width: 50%;
  transition: width 0s ease-in-out; /* 添加 transition 效果 */
  /* margin-top: 10px; */
}
.active-link .routeText1::after {
  width: 85%;
  margin-left: 8px;
}
.active-link .routeText2::after {
  width: 75%;
  margin-left: 15px;
}
.active-link .routeText3::after {
  margin-left: 8px;
  width: 85%;
}
.active-link .routeText4::after {
  width: 80%;
  margin-left: 20px;
}

.title {
  /* border: 1px solid red; */
  width: 500px;
}
.secondBox {
  padding: 0 120px 0 90px;
  border-bottom: 1px solid #d6d6d6;
}


.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 10%;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  padding: 0px 0;
  min-width: 180px;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.dropdown-menu li:first-child:hover {
  border-radius: 12px 12px 0 0;
}
.dropdown-menu li:nth-child(2):hover {
  border-radius: 0 0 12px 12px;
}
.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li a {
  font-size: 15px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #000000;
  padding-left: 20px;
  padding-top: 2px;
  display: flex;
  width: 100%;
}
.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* 设置倒三角形的颜色 */
  border-bottom: none; /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}
.firstLi {
  border-bottom: 1px solid #cccccc;
}
.iconImg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  /* padding-left: -20px; */
  /* margin: 0 10px 0 auto; */
}

</style>
